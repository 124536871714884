import { Fade } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

function TerminosCondiciones(props) {
  const [display, setDisplay] = useState(false);
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (!props.location.from) {
      setInit(true);
    }
    if (
      !props.location.from?.startsWith("/cursos/") &&
      !props.location.from?.startsWith("/preguntas")
    ) {
      setTimeout(() => {
        setDisplay(true);
      }, 1400);
    } else {
      setDisplay(true);
    }

    return () => {};
  }, []);
  return (
    <Fade
      direction="up"
      in={display || init}
      timeout={init ? 0 : display ? 500 : 0}
      unmountOnExit={true}
      mountOnEnter={true}
    >
      <div className="terminos_cnt">
        <h1>Términos y condiciones</h1>
        <h3>1. Inscripción y Pago:</h3>
        <ul>
          <li>
            <span>1.1</span> La inscripción se realizará completando el
            formulario proporcionado por la escuela.
          </li>
          <li>
            <span>1.2</span> El pago del curso se realizará antes del inicio del
            programa. Los detalles sobre las tarifas y los métodos de pago se
            proporcionarán durante el proceso de inscripción.
          </li>
        </ul>
        <h3>2. Duración y Horarios:</h3>
        <ul>
          <li>
            <span>2.1</span> La duración de cada curso es de 3 meses, con clases
            presenciales los sábados de 9 a 2.
          </li>
          <li>
            <span>2.2</span> La puntualidad es crucial. Los estudiantes deben
            llegar a tiempo para aprovechar al máximo las clases.
          </li>
        </ul>
        <h3>3. Contenido del Curso:</h3>
        <ul>
          <li>
            <span>3.1</span> La escuela se compromete a proporcionar educación
            en áreas como desarrollo web, aplicaciones, marketing, internet de
            las cosas, y temas relacionados.
          </li>
          <li>
            <span>3.2</span> El contenido del curso puede estar sujeto a cambios
            para mejorar la calidad de la enseñanza.
          </li>
        </ul>
        <h3>4. Cancelaciones y Reembolsos:</h3>
        <ul>
          <li>
            <span>4.1</span> Los estudiantes pueden cancelar su participación
            antes del inicio del curso y recibir un reembolso completo.
          </li>
          <li>
            <span>4.2</span> La escuela se reserva el derecho de cancelar un
            curso debido a razones imprevistas. En este caso, se ofrecerá un
            reembolso completo o la opción de inscribirse en un curso futuro.
          </li>
        </ul>
        <h3>5. Conducta del Estudiante:</h3>
        <ul>
          <li>
            <span>5.1</span> Se espera que los estudiantes mantengan un
            comportamiento respetuoso y ético en todo momento.
          </li>
          <li>
            <span>5.2</span> La escuela se reserva el derecho de expulsar a
            cualquier estudiante que viole las normas de conducta sin derecho a
            reembolso.
          </li>
        </ul>
        <h3>6. Propiedad Intelectual:</h3>
        <ul>
          <li>
            <span>6.1</span> Todo el material didáctico proporcionado por la
            escuela está protegido por derechos de autor y no puede ser
            reproducido sin permiso.
          </li>
          <li>
            <span>6.2 </span> Los proyectos desarrollados por los estudiantes
            son de su propiedad, pero la escuela puede mostrarlos con fines
            promocionales.
          </li>
        </ul>
        <p>
          Estos términos y condiciones son solo un punto de partida y se
          recomienda consultar con un profesional legal para asegurarse de que
          cumplan con las leyes locales y aborden todas las situaciones
          posibles.
        </p>
        <div style={{ height: 50 }}></div>
      </div>
    </Fade>
  );
}

export default withRouter(TerminosCondiciones);
