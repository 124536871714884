import moment from "moment";
import React, { Component } from "react";

export default class Details extends Component {
  render() {
    const { details } = this.props;
    return details ? (
      <div style={{ width: "30vw" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <p
            style={{
              textAlign: "end",
              backgroundColor:
                details.type === "income" ? "rgb(50 149 11)" : "#D51000",
              color: "white",
              fontWeight: "bold",
              width: "fit-content",
              padding: 10,
              borderRadius: 20,
            }}
          >
            {` ${details.type === "income" ? "+" : "-"} $${details.amount}`}
          </p>
          <p>{moment(details.date).format("dddd D \\d\\e MMMM yyyy")}</p>
        </div>
        <p style={{ wordBreak: "break-all" }}>
          {details.details || "Sin detalles"}
        </p>
      </div>
    ) : (
      <div></div>
    );
  }
}
