import { CircularProgress, Grow, List, ListItem } from "@material-ui/core";
import { Slide } from "@mui/material";
import React, { Component, useState } from "react";
import { withRouter } from "react-router";
import firebase from "../Firebase";
import { cursos } from "../utils/constants";
import img1 from "../assets/img/large-window-provides-natural-light-to-a-workspace.jpg";
import img2 from "../assets/img/spacejoy-4As-RmiQPB4-unsplash.jpg";
import img3 from "../assets/img/three-woman-hold-a-meeting-at-a-dark-wooden-table.jpg";
import img4 from "../assets/img/woman-working-on-laptop-on-patio.jpg";

function MyWork(props) {
  const [projects, setProjects] = useState([
    {
      id: "web",
      gallery: [img1],
      name: "Curso de Desarrollo de Páginas Web",
    },
    {
      id: "app",
      gallery: [img2],
      name: "Curso de Elaboración de Aplicaciones (Apps)",
    },
    {
      id: "proximos",
      gallery: [img4],
      name: "Próximos cursos",
    },
    {
      id: "preguntas",
      gallery: [img3],
      name: "Preguntas frecuentes",
    },
  ]);

  const _onClick = (id) => {
    if (id == "preguntas") {
      props.history.push({
        pathname: `/${id}`,
        from: "/cursos",
      });
      return;
    }
    props.history.push({
      pathname: `/cursos/${id}`,
      from: "/cursos",
    });
  };

  const _renderBody = () => {
    return (
      <div className="projectsList">
        <div className="titleContainer">
          <Slide in={true} unmountOnExit timeout={1000} direction="right">
            <h1>{cursos}</h1>
          </Slide>
        </div>

        <div>
          <List className="list">
            {projects.length > 0 &&
              projects.map((project) => {
                return (
                  <Grow in={true} timeout={1200} key={project.id}>
                    <ListItem
                      className="item-container"
                      onClick={() => _onClick(project.id)}
                    >
                      <div className="image-container">
                        {project.gallery !== undefined ? (
                          <img
                            alt={project.name}
                            src={project.gallery[0]}
                          ></img>
                        ) : (
                          <div className="projectTitle">{project.name[0]}</div>
                        )}
                        <span> {project.name}</span>
                      </div>
                    </ListItem>
                  </Grow>
                );
              })}
          </List>
        </div>
      </div>
    );
  };
  return (
    <>
      <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={500}>
        {_renderBody()}
      </Slide>
    </>
  );
}

export default withRouter(MyWork);
