import { Fade, Slide } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import img1 from "../../assets/img/spacejoy-4As-RmiQPB4-unsplash.jpg";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { KeyboardArrowRight } from "@material-ui/icons";

function CursosApp(props) {
  const _onClick = (e, page) => {
    console.log(props);
    e.preventDefault();
    props.history.push({
      pathname: `/cursos/${page}`,
      from: "/cursos/app",
    });
  };

  return (
    <div className="cursoCnt">
      <Fade in={true} timeout={800}>
        <div className="cursoInfo">
          <div>
            <h1 style={{ fontWeight: "bold" }}>
              Curso de Elaboración de Aplicaciones (Apps)
            </h1>
            <p>
              En nuestro curso de elaboración de aplicaciones, los estudiantes
              aprenderán a desarrollar aplicaciones móviles para diversas
              plataformas, incluyendo Android e iOS. Las cinco características
              clave del curso son:
            </p>
            {/* <h3 style={{ fontWeight: "bold" }}>¿Qué aprenderás?</h3> */}
            <ul>
              <li>
                Plataformas Móviles: Explorarás el desarrollo de aplicaciones
                para Android e iOS, comprendiendo las diferencias y similitudes
                entre las dos plataformas.
              </li>
              <li>
                Diseño de Interfaz de Usuario (UI/UX): Aprenderás a diseñar
                interfaces de usuario intuitivas y atractivas para una
                experiencia del usuario superior.
              </li>
              <li>
                Integración de Funcionalidades: Implementación de
                características como geolocalización, notificaciones push y
                acceso a cámaras para crear aplicaciones dinámicas.
              </li>
              <li>
                Monetización: Estrategias para monetizar aplicaciones a través
                de publicidad, compras dentro de la aplicación y modelos de
                suscripción.
              </li>
              <li>
                Pruebas y Depuración: Técnicas para realizar pruebas exhaustivas
                y depuración para garantizar que las aplicaciones sean estables
                y confiables.
              </li>
            </ul>
          </div>
          <img
            src={img1}
            className="cursoImg"
            // style={{ width: "40%", borderRadius: 10, objectFit: "cover" }}
          />
        </div>
      </Fade>
      <div className="footer">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
            padding: "0px 20px",
          }}
        >
          <a
            className="redirectBtn"
            href={`#`}
            onClick={(e) => _onClick(e, "web")}
          >
            <span> Curso de Elaboración de Aplicaciones (Apps)</span>
            <KeyboardArrowLeft />
          </a>
          <a
            className="redirectBtn"
            href={`#`}
            onClick={(e) => _onClick(e, "proximos")}
          >
            <span>Próximos cursos</span>
            <KeyboardArrowRight />
          </a>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CursosApp);
