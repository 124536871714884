import { Fade } from "@material-ui/core";
import { KeyboardArrowLeft } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

function Q_A(props) {
  const [display, setDisplay] = useState(false);
  const [init, setInit] = useState(false);

  const preguntas = [
    {
      q: "¿Qué costo tiene el curso?",
      a: "$2,500.00 pesos mensuales, el curso dura 3 meses. ",
    },
    {
      q: " ¿Cuánto dura el curso?",
      a: "El curso tiene una duración de 3 meses.",
    },
    {
      q: "¿Me dan un certificado avalado por la SEP?",
      a: "No, sin embargo, te garantizamos que al término del curso contarás con tu propia página de internet con proyectos que realizaste. Esa página se llama portafolio y te sirve para acreditar en una entrevista de trabajo que cuentas con los conocimientos suficientes para un puesto de programador o en su caso para que vean tus amigos y familiares que les puedes hacer su página de internet personalizada.",
    },
    {
      q: "¿En cuánto se puede vender una página web?",
      a: "Es muy variable el precio, ya que depende de las funcionalidades que tenga, puede costar de entre $5,000.00 pesos hasta millones de pesos.",
    },
    {
      q: " ¿Es presencial o en línea el curso?",
      a: "Es 100% presencial",
    },
    {
      q: "¿Necesito una laptop para tomar el curso?",
      a: "Preferentemente sí, en caso contrario hablar con el coordinador del curso.",
    },
    {
      q: "¿Qué días y en qué horario se impartirá el curso?",
      a: "Sábados de 9:00 a 2:00 (incluye dos descansos de 15 minutos). Los cursos se imparten en sábado para que te permitas seguir trabajando y estudiando y preparándote para convertirte en un nómada digital.",
    },
    {
      q: "¿Para quién va dirigido el curso?",
      a: "Va dirigido para profesionistas que quieren adquirir nuevas habilidades en el ámbito tecnológico para implementarlos en su vida profesional o incluso perfilarse a una nueva vida laboral; también va dirigido para estudiantes que no tienen el tiempo para cubrir una carrera universitaria y quieren desarrollar una habilidad para iniciar su propio negocio.",
    },
    {
      q: "¿En dónde se va a impartir el curso?",
      a: "Al concretar la cita se te indicará la dirección",
    },
    {
      q: "¿Qué características tienen las instalaciones?",
      a: "Espacios cómodos y amplios para poder aprender al máximo.",
    },
  ];

  useEffect(() => {
    if (!props.location.from) {
      setInit(true);
    }
    if (!props.location.from?.startsWith("/cursos/")) {
      console.log("OKKKK", props.location.from);
      setTimeout(() => {
        setDisplay(true);
      }, 1300);
    } else {
      setDisplay(true);
    }
  }, []);

  const _onClick = (e) => {
    console.log(props);
    e.preventDefault();
    props.history.push({
      pathname: `/cursos/proximos`,
      from: "/preguntas",
    });
  };

  return (
    <Fade
      direction="up"
      in={display || init}
      timeout={init ? 0 : display ? 500 : 0}
      unmountOnExit={true}
      mountOnEnter={true}
    >
      <div
        className="background cursoCnt"
        style={{
          marginTop: 60,
          width: "100vw",
          height: "92vh",
          overflow: "scroll",
        }}
      >
        <div className="cursoInfo" style={{ paddingBottom: "10rem" }}>
          <div>
            <h1>Preguntas frecuentes</h1>
            <p style={{ marginTop: 50 }}>
              <ol>
                {preguntas.map((item) => (
                  <>
                    <li style={{ fontSize: 20 }}>{item.q}</li>
                    <p style={{ fontWeight: "bold", marginTop: 10 }}>
                      {item.a}
                    </p>
                  </>
                ))}
              </ol>
            </p>
          </div>
        </div>
        <div className="footer">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100%",
              padding: "0px 20px",
            }}
          >
            <a className="redirectBtn" href={`#`} onClick={_onClick}>
              <span>Próximos cursos</span>
              <KeyboardArrowLeft />
            </a>
          </div>
        </div>
      </div>
    </Fade>
  );
}

export default withRouter(Q_A);
