import React, { Component } from "react";
import {
  Document,
  Page,
  PDFDownloadLink,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";

export default class PdfContainer extends Component {
  renderElement({ name, date, details, type }) {
    let format = moment(date).format("D-MMM-yyyy");
    return (
      <View style={styles.element}>
        <Text
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            overflow: "hidden",
            fontSize: "17px",
          }}
        >
          {name}
        </Text>
        <Text style={{ marginLeft: "auto", fontSize: "15px" }}>{format}</Text>
      </View>
    );
  }
  doc = () => {
    const { expenses, incomes, date } = this.props;
    console.log(expenses, incomes, date.startDate);
    let formatDate = moment(date.startDate).format(
      "D \\d\\e MMMM \\d\\e\\l yyyy"
    );

    return (
      <Document>
        <Page wrap style={styles.page}>
          <Text
            style={{
              marginRight: "auto",
              marginLeft: "auto",
              marginBottom: "40px",
              fontWeight: "bold",
              fontSize: "30px",
            }}
          >
            Reporte de Ingresos y Gastos
          </Text>
          <Text style={{ marginLeft: "auto", marginBottom: "20px" }}>
            {formatDate}
          </Text>
          <View
            render={() => (
              <View style={styles.header}>
                <Text>Ingresos</Text>
                <Text>Gastos</Text>
              </View>
            )}
            fixed
          />

          <View style={styles.body}>
            <View style={{ width: "50%" }}>
              {incomes.map((element) => this.renderElement(element))}
            </View>
            <View style={{ width: "50%" }}>
              {expenses.map((element) => this.renderElement(element))}
            </View>
          </View>
          <Text
            render={({ pageNumber, totalPages }) => `${pageNumber}`}
            fixed
            style={styles.footer}
          />
        </Page>
      </Document>
    );
  };
  render() {
    return (
      <PDFViewer style={{ width: "100%", height: "100%", marginTop: "4rem" }}>
        {this.doc()}
      </PDFViewer>
      // <PDFDownloadLink document={this.doc()} fileName="mypdf.pdf">
      //   {({ blob, url, loading, error }) =>
      //     loading ? "Loading document..." : "Download now!"
      //   }
      // </PDFDownloadLink>
    );
  }
}

const styles = StyleSheet.create({
  page: {
    padding: "40px",
  },
  header: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    marginBottom: "10px",
    marginTop: "20px",
  },
  body: {
    flexDirection: "row",
    width: "100%",

    marginBottom: "10px",
    marginTop: "20px",
  },
  footer: {
    position: "absolute",
    bottom: "20px",
    right: "20px",
  },
  element: {
    marginBottom: "20px",
    paddingLeft: "20px",
    display: "flex",
    flexDirection: "row",
  },
});
