import { createStore, combineReducers } from "redux";
import animationActions from "./reducers/animationActions";
import { reducer as formReducer } from "redux-form";
const reducer = combineReducers({
  form: formReducer,
});

const store = createStore(reducer);

export default store;
