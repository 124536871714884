import React, { Component } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import gsap from "gsap";
import { withRouter } from "react-router";
import { manager } from "../utils/LoaderContext";
import { cameraPositions } from "../utils/cameraPositions";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";

class Seekerbot extends Component {
  _camera = undefined;
  _mixer = undefined;
  _animations = [];
  _orbitControls = undefined;
  _isMounted = false;
  _timeoutFunc = undefined;
  _listener = undefined;
  _renderer = undefined;
  _params = {
    exposure: 2,
    bloomStrength: 0.5,
    bloomThreshold: 0,
    bloomRadius: 1,
  };
  _cameraPosition = cameraPositions["horizontal"];
  constructor(props) {
    super(props);
    this.state = {
      width: undefined,
      height: undefined,
      domElement: undefined,
      currentState: undefined,
      prevState: undefined,
      cameraPosition: undefined,
      eyesState: undefined,
    };
    this._scene = new THREE.Scene();
    this._domElement = undefined;
    this._robot = new THREE.Group();
  }
  /**
   *
   * @param {THREE.WebGLRenderer} renderer
   * @param {EffectComposer} composer
   */
  _resize(renderer, composer) {
    let position = this.setPosition();

    let getCameraPositions = this._getCameraPositions(position);
    let { x, y, z, rotationx, rotationy, rotationz } = getCameraPositions;
    this._camera.position.set(x, y, z);
    this._orbitControls.target = new THREE.Vector3(
      rotationx,
      rotationy,
      rotationz
    );
    this._camera.aspect =
      this.mountRef.clientWidth / this.mountRef.clientHeight;
    this._camera.updateProjectionMatrix();
    renderer.setSize(this.mountRef.clientWidth, this.mountRef.clientHeight);
    composer.setSize(this.mountRef.clientWidth, this.mountRef.clientHeight);
  }

  _getCameraPositions(position) {
    switch (position) {
      case "back":
        if (
          window.innerWidth <= 1200 &&
          window.innerWidth < window.innerHeight
        ) {
          return cameraPositions["vertical"][position];
        }
        return cameraPositions["horizontal"][position];
      case "front":
        if (window.innerWidth <= 1250) {
          return cameraPositions["vertical"][position];
        }
        return cameraPositions["horizontal"][position];
      case "frontZoom":
        if (
          window.innerHeight < 750 ||
          window.innerWidth < window.innerHeight
        ) {
          return cameraPositions["frontZoom2"];
        }
        return cameraPositions["horizontal"][position];
      case "frontZoom2":
        return cameraPositions[position];
      default:
        if (window.innerWidth <= 600) {
          return cameraPositions["vertical"][position];
        }
        return cameraPositions["horizontal"][position];
    }
  }

  setPosition() {
    switch (this.props.match.path) {
      case "/":
        return "side-face";

      case "/cursos":
        return "back";

      case "/about":
        return "front";

      case "/contact":
        return "frontZoom";

      case "/cursos/:id":
        return "screen";
      case "/terminos_y_condiciones":
        return "screen";
      case "/preguntas":
        return "screen";
      default:
        return "side-face";
    }
  }

  componentDidMount() {
    this._isMounted = true;
    const currentRef = this.mountRef;
    const { clientWidth: width, clientHeight: height } = currentRef;
    let position = this.setPosition();
    let coordenates = this._getCameraPositions(position);

    this.setState({ width: width, height: height }, () => {
      let { rotationx, rotationy, rotationz } = coordenates;
      this.addScene(coordenates);
      this._renderer = new THREE.WebGLRenderer({ antialias: true });
      this._renderer.setSize(width, height);
      this._orbitControls = new OrbitControls(
        this._camera,
        this._renderer.domElement
      );
      this._orbitControls.target = new THREE.Vector3(
        rotationx,
        rotationy,
        rotationz
      );
      this._domElement = this._renderer.domElement;
      currentRef.appendChild(this._domElement);
      this._loadModel();

      const renderScene = new RenderPass(this._scene, this._camera);
      const bloomPass = new UnrealBloomPass(
        new THREE.Vector2(window.innerWidth, window.innerHeight),
        1.5,
        0.4,
        0.85
      );
      bloomPass.threshold = this._params.bloomThreshold;
      bloomPass.strength = this._params.bloomStrength;
      bloomPass.radius = this._params.bloomRadius;

      const composer = new EffectComposer(this._renderer);
      composer.addPass(renderScene);
      composer.addPass(bloomPass);
      this._RAF(this._renderer, composer);
      this._eventListener = () => this._resize(this._renderer, composer);
      window.addEventListener("resize", this._eventListener, false);
    });
  }

  _animationInit() {
    let current;
    let currentEyes;
    switch (this.props.match.path) {
      case "/about":
        current = "about";
        currentEyes = "blink";
        break;
      case "/contact":
        let action2 = this._animations["phone_action"].action;
        action2.clampWhenFinished = true;
        action2.setLoop(THREE.LoopOnce);
        action2.play();
        action2.reset();
        current = "phone";
        currentEyes = "blink";
        break;
      default:
        currentEyes = "blink";
        current = "typing";
        break;
    }
    if (this._isMounted) {
      this.setState(
        {
          currentState: this._animations[current],
          eyesState: this._animations[currentEyes],
        },
        () => {
          let action = this._animations[current].action;
          action.clampWhenFinished = true;

          let eyesAction = this._animations[currentEyes].action;
          eyesAction.clampWhenFinished = true;
          if (current === "about" || current === "phone") {
            action.startAt(0);
            action.setLoop(THREE.LoopRepeat, current === "about" ? 2 : 1);
            this._listener = (e) => {
              this._finishAction(e.action);
            };
            action.getMixer().addEventListener("finished", this._listener);
          }
          //action.enabled = true;
          action.play();
          eyesAction.play();

          // this._timeoutFunc = setTimeout(()=>{
          //   action.stop();
          //   this._finishAction(action)
          // }, 2000);
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.path !== prevProps.match.path) {
      if (this._listener) {
        this._mixer.removeEventListener("finished", this._listener);
      }
      if (this._timeoutFunc) {
        clearTimeout(this._timeoutFunc);
      }
      let phoneAction = this._animations["phone_action"].action;
      phoneAction.reset();
      phoneAction.stop();
      let coordenates;
      switch (this.props.match.path) {
        case "/":
          this._state("typing");
          coordenates = this._getCameraPositions("side-face");
          this._setCameraPosition(coordenates);
          break;
        case "/cursos":
          // if (this.state.currentState.clip.name !== "click") {
          //   this._setCameraPosition()
          // }
          this._state("click");
          coordenates = this._getCameraPositions("back");
          this._setCameraPosition(coordenates);
          break;
        case "/about":
          this._state("about");
          coordenates = this._getCameraPositions("front");
          this._setCameraPosition(coordenates);
          break;
        case "/cursos/:id":
          this._state("click");
          coordenates = this._getCameraPositions("screen");
          this._setCameraPosition(coordenates);
          break;
        case "/terminos_y_condiciones":
          this._state("click");
          coordenates = this._getCameraPositions("screen");
          this._setCameraPosition(coordenates);
          break;
        case "/preguntas":
          this._state("click");
          coordenates = this._getCameraPositions("screen");
          this._setCameraPosition(coordenates);
          break;
        case "/contact":
          this._state("phone");
          coordenates = this._getCameraPositions("frontZoom");

          this._setCameraPosition(coordenates);
          if (
            window.innerHeight < 750 ||
            window.innerWidth < window.innerHeight
          ) {
            setTimeout(() => {
              coordenates = this._getCameraPositions("frontZoom2");
              this._setCameraPosition(coordenates);
            }, 1000);
          }

          break;
        default:
          break;
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    let spotLight = this._scene.getObjectByName("spotlight");
    this._scene.remove(spotLight);
    let pointLight = this._scene.getObjectByName("pointLight");
    this._scene.remove(pointLight);
    this.pointLight2 = this._scene.getObjectByName("pointLight2");
    this._scene.remove(this.pointLight2);
    this._scene.remove(this._robot);
    this.mountRef.removeChild(this._renderer.domElement);
    this._renderer.dispose();
    window.removeEventListener("resize", this._eventListener);
  }
  _loadModel() {
    const hemiLight = new THREE.HemisphereLight(0xebfcff, 0xffffff, 0.1);

    this._scene.add(hemiLight);
    const spotLight = new THREE.DirectionalLight(0xffffff, 0.4);
    spotLight.castShadow = true;
    spotLight.distance = 10;
    spotLight.shadow.bias = -0.001;
    spotLight.shadow.mapSize.width = 1024 * 3;
    spotLight.shadow.mapSize.height = 1024 * 3;
    this._scene.add(spotLight);
    spotLight.name = "spotlight";
    spotLight.position.set(6, 10, 1);

    const pointLight = new THREE.PointLight(0x90efff, 0.8);
    pointLight.distance = 1;

    pointLight.name = "pointLight";
    pointLight.position.set(0, 0, -0.5);
    this._scene.add(pointLight);
    const pointLight2 = new THREE.PointLight(0xebfcff, 0.25);
    pointLight2.distance = 35;
    pointLight2.position.set(-1, 1, 5);
    pointLight2.name = "pointLight2";
    this._scene.add(pointLight2);

    const cubeTextureLoader = new THREE.CubeTextureLoader();
    const evm = cubeTextureLoader.load([
      "envMap/studio/px.png",
      "envMap/studio/nx.png",
      "envMap/studio/py.png",
      "envMap/studio/ny.png",
      "envMap/studio/pz.png",
      "envMap/studio/nz.png",
    ]);

    const gltfLoader = new GLTFLoader(manager);
    const that = this;
    gltfLoader.load("/models/seekerbot/seekerbot_developer.gltf", (gltf) => {
      gltf.scene.scale.set(0.5, 0.5, 0.5);
      gltf.scene.traverse(function (object) {
        object.frustumCulled = false;
        if (object.isObject3D) {
          object.castShadow = true;
          object.receiveShadow = true;
        }
      });
      let eyes = gltf.scene.getObjectByName("Ojos_1");
      let windows = gltf.scene.getObjectByName("scene");
      windows.children[2].material.emissiveIntensity = 0.5;
      let ledAntenna = gltf.scene.getObjectByName("FocoLuz");
      ledAntenna.material.emissiveIntensity = 2;
      let phoneScreen =
        gltf.scene.getObjectByName("phone").children[2].material;
      phoneScreen.emissiveIntensity = 0.3;
      let chair = gltf.scene.getObjectByName("chair");
      chair.children[0].material.envMap = evm;
      let ears = gltf.scene.getObjectByName("Oreja");
      ears.children[0].material.envMap = evm;
      //  let head = gltf.scene.getObjectByName("Cabeza");
      //  head.children[0].material.envMap = evm;
      let scenario = gltf.scene.getObjectByName("scene");
      let desk = gltf.scene.getObjectByName("desk");
      desk.children[0].material.envMap = evm;
      scenario.children[0].material.envMap = evm;
      eyes.name = "Ojos";
      // let morphChange = ()=>{
      //   gltf
      // }

      that._mixer = new THREE.AnimationMixer(gltf.scene);
      gltf.animations.forEach((animation, index) => {
        const clip = gltf.animations[index];
        const action = that._mixer.clipAction(clip);

        that._animations[animation.name] = {
          clip: clip,
          action: action,
        };
      });
      this._robot.add(gltf.scene);
      this._robot.position.y = -1;
      this._robot.name = "robot";
      this._animationInit();
      this._scene.add(this._robot);
    });
  }

  addScene(coordenates) {
    //  this._scene.background = new THREE.Color(0xffffff);
    this._camera = new THREE.PerspectiveCamera(
      45,
      this.state.width / this.state.height,
      0.01,
      2000
    );
    this._scene.add(this._camera);

    this._camera.position.set(coordenates.x, coordenates.y, coordenates.z);
  }

  /**
   *
   * @param {THREE.WebGLRenderer} renderer
   * @param {THREE.Scene} scene
   * @param {THREE.PerspectiveCamera} camera
   * @param {EffectComposer} composer
   */
  _RAF(renderer, composer) {
    renderer.toneMapping = THREE.ReinhardToneMapping;
    renderer.toneMappingExposure = this._params.exposure;
    renderer.shadowMap.enabled = true;
    const that = this;
    const clock = new THREE.Clock();

    const animate = () => {
      requestAnimationFrame(animate);
      if (that._orbitControls) {
        that._orbitControls.update();
      }
      const dt = clock.getDelta();
      if (that._mixer) {
        that._mixer.update(dt);
      }
      renderer.autoClear = false;
      renderer.clear();
      composer.render();
      renderer.render(this._scene, this._camera);
    };
    animate();
  }
  _finishAction(action) {
    let mixer = action.getMixer();
    mixer.removeEventListener("finished", this._listener);
    let actionName = action._clip.name;
    switch (actionName) {
      case "click":
        this._state("typing");
        break;

      case "phone":
        break;

      default:
        if (
          actionName === "about" ||
          actionName === "waiting" ||
          actionName === "waiting2" ||
          actionName === "stretching" ||
          actionName === "headbang" ||
          actionName === "suit_chair" ||
          actionName === "about"
        ) {
          let action = this._waitingActions(actionName);

          this._timeoutFunc = setTimeout(() => {
            if (this.state.currentState.clip.name === actionName) {
              this._state(action);
            }
          }, 1000);
        } else {
          this._state("typing");
        }

        break;
    }
  }

  _waitingActions(currentAction) {
    let actions = [
      "stretching",
      "waiting",
      "waiting2",
      "headbang",
      "suit_chair",
      "about",
    ];
    let random = Math.floor(Math.random() * 6);
    if (actions[random] === currentAction) {
      return this._waitingActions(currentAction);
    }

    return actions[random];
  }

  _eyesState(name) {
    if (name !== this.state.eyesState.clip.name) {
      this.state.eyesState.action.stop();
      this.setState({ eyesState: this._animations[name] }, () => {
        this.state.eyesState.action.play();
      });
    }
  }

  _state(name) {
    const prevState = this.state.currentState;
    if (this._isMounted) {
      this.setState({ currentState: this._animations[name] }, () => {
        let prevAction = prevState.action;
        let currentAction = this.state.currentState.action;
        currentAction.reset();

        currentAction.clampWhenFinished = true;
        switch (name) {
          case "click":
            this._eyesState("blink");
            currentAction.setLoop(THREE.LoopOnce, 1);
            currentAction.crossFadeFrom(prevAction, 0.2, true);
            break;
          case "about":
            this._eyesState("blink");
            currentAction.setLoop(THREE.LoopOnce, 1);
            currentAction.crossFadeFrom(prevAction, 0.2, true);
            break;
          case "typing":
            this._eyesState("blink");
            currentAction.crossFadeFrom(prevAction, 0.2, true);
            break;
          case "waiting":
            this._eyesState("waitingEyes");
            currentAction.setLoop(THREE.LoopOnce, 1);
            currentAction.crossFadeFrom(prevAction, 0, true);
            break;
          case "stretching":
            this._eyesState("stretchingEyes");
            currentAction.setLoop(THREE.LoopOnce, 1);
            currentAction.crossFadeFrom(prevAction, 0, true);
            break;
          case "waiting2":
            this._eyesState("blink");
            currentAction.setLoop(THREE.LoopOnce, 1);
            currentAction.crossFadeFrom(prevAction, 0, true);
            break;
          case "headbang":
            this._eyesState("blink");
            currentAction.setLoop(THREE.LoopRepeat, 1);
            currentAction.crossFadeFrom(prevAction, 0, true);
            break;
          case "suit_chair":
            this._eyesState("blink");
            currentAction.setLoop(THREE.LoopRepeat, 1);
            currentAction.crossFadeFrom(prevAction, 0, true);
            break;
          case "phone":
            this._eyesState("blink");
            currentAction.setLoop(THREE.LoopOnce, 1);
            currentAction.crossFadeFrom(prevAction, 0.2, true);
            let action2 = this._animations["phone_action"].action;
            action2.setLoop(THREE.LoopOnce);
            action2.clampWhenFinished = true;
            action2.play();

            break;
          default:
            currentAction.crossFadeFrom(prevAction, 0, true);
            break;
        }

        currentAction.play();
        let mixer = currentAction.getMixer();
        this._listener = (e) => {
          this._finishAction(e.action);
        };
        mixer.addEventListener("finished", this._listener);
      });
    }
  }

  _setCameraPosition(coordenates) {
    const that = this;
    let { x, y, z, rotationx, rotationy, rotationz } = coordenates;
    gsap.to(this._camera.position, {
      duration: 1.5,
      x: x,
      z: z,
      y: y,
      onUpdate: function () {
        that._camera.updateProjectionMatrix();
      },
    });
    gsap.to(this._orbitControls.target, {
      duration: 1.5,
      x: rotationx,
      z: rotationz,
      y: rotationy,
      onUpdate: function () {
        that._orbitControls.update();
      },
    });
  }

  onClick() {
    this._state("click");

    // gsap.to(this._orbitControls, {
    //   duration: 2,
    //   z:-5,
    //   onUpdate: function () {
    //     that._orbitControls.update()
    //   }
    // })
  }

  render() {
    return (
      <div
        className="Seekerbot3D"
        ref={(ref) => (this.mountRef = ref)}
        style={{ width: "100%", height: "100vh" }}
      ></div>
    );
  }
}

export default withRouter(Seekerbot);
