import { Dialog } from "@material-ui/core";
import moment from "moment";
import React, { Component } from "react";
import { WindowContext } from "../../utils/WindowContext";
import SelectDateForm from "./SelectDateForm";

export default class SelectDate extends Component {
  _isMouted = false;
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      date: {
        startDate: moment(new Date()).startOf("day").toDate(),
        endDate: moment(new Date()).startOf("day").toDate(),
      },
    };
  }

  componentDidMount() {
    this._isMouted = true;
    this.props.onChangeDate(this.state.date);
  }

  componentWillUnmount() {
    this._isMouted = false;
  }

  handleDialogState() {
    if (this._isMouted) {
      this.setState({ showDialog: !this.state.showDialog });
    }
  }

  handleSubmit = ({ start, end }) => {
    if (this._isMouted) {
      this.setState(
        {
          showDialog: false,
          date: {
            startDate: moment(start).toDate(),
            endDate: moment(end).toDate(),
          },
        },
        () => {
          this.props.onChangeDate(this.state.date);
        }
      );
    }
  };

  render() {
    return (
      <WindowContext.Consumer>
        {(window) => {
          return (
            <div>
              <Dialog
                open={this.state.showDialog}
                onClose={() => this.handleDialogState()}
              >
                <div className="dialogHeader">Seleccionar Fecha</div>
                <div
                  style={{
                    display: "flex",
                    padding: "20px",
                    maxHeight: "1000px",
                    width: "100%",
                  }}
                >
                  <SelectDateForm
                    onSubmit={this.handleSubmit}
                    start={this.state.date.startDate}
                    end={this.state.date.endDate}
                  />
                </div>
              </Dialog>
              <button
                onClick={() => this.handleDialogState()}
                className="inputStyle"
              >
                <div>
                  {`${moment(this.state.date.startDate).format(
                    window.resize.width >= 830
                      ? "dddd D \\d\\e MMMM \\d\\e yyyy"
                      : "D/MM/yyyy"
                  )}
              ${
                moment(this.state.date.endDate).diff(
                  this.state.date.startDate
                ) !== 0
                  ? " - " +
                    moment(this.state.date.endDate).format(
                      window.resize.width >= 830
                        ? "dddd D \\d\\e MMMM \\d\\e yyyy"
                        : "D/MM/yyyy"
                    )
                  : ""
              }`}
                </div>
              </button>
            </div>
          );
        }}
      </WindowContext.Consumer>
    );
  }
}
