import { Fade, Slide } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import img1 from "../../assets/img/woman-working-on-laptop-on-patio.jpg";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { KeyboardArrowRight } from "@material-ui/icons";

function ProxCursos(props) {
  const _onClick = (e) => {
    console.log(props);
    e.preventDefault();
    props.history.push({
      pathname: `/cursos/app`,
      from: "/cursos/proximos",
    });
  };

  const q_aClick = (e) => {
    e.preventDefault();
    props.history.push({
      pathname: `/preguntas`,
      from: "/cursos/proximos",
    });
  };

  return (
    <div className="cursoCnt">
      <Fade in={true} timeout={800}>
        <div className="cursoInfo">
          <div>
            <h1 style={{ fontWeight: "bold" }}>Próximos Cursos</h1>
            <p style={{ marginTop: 50 }}>
              Curso de Internet de las Cosas (IoT): Exploraremos cómo conectar
              dispositivos físicos a través de internet, permitiendo la
              automatización y el control remoto.
            </p>
            <p>
              Curso de Manejo de Base de Datos y Marketing Digital: Aprenderás
              sobre el diseño y gestión de bases de datos, así como estrategias
              efectivas de marketing digital para promocionar productos y
              servicios en línea.
            </p>
          </div>
          <img
            src={img1}
            className="proxCursosImg"
            // style={{ width: "40%", borderRadius: 10, objectFit: "cover" }}
          />
        </div>
      </Fade>
      <div className="footer">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
            padding: "0px 20px",
          }}
        >
          <a className="redirectBtn" href={`#`} onClick={_onClick}>
            <span>Curso de Elaboración de Aplicaciones (Apps)</span>
            <KeyboardArrowLeft />
          </a>
          <a className="redirectBtn" href={`#`} onClick={q_aClick}>
            <span>Preguntas frecuentes</span>
            <KeyboardArrowRight />
          </a>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ProxCursos);
