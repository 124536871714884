import {
  AppBar,
  Button,
  Fade,
  SwipeableDrawer,
  Toolbar,
} from "@material-ui/core";
import { Slide } from "@mui/material";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { AuthContext } from "../utils/AuthContext";
import firebase from "../Firebase";
import { WindowContext } from "../utils/WindowContext";
import { Menu } from "@material-ui/icons";
import { about, cursos } from "../utils/constants";

class AppHeader extends Component {
  static contextType = AuthContext;
  _isMounted = false;
  constructor(props, context) {
    super(props);
    this.state = {
      openMenu: false,
    };
  }

  _clickAction(route) {
    if (this.state.openMenu) {
      this.setState({ openMenu: false });
    }

    this.props.history.push({
      pathname: route,
      from: this.props.location.pathname,
    });
  }
  logOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {});
    localStorage.clear();
    this.props.history.push("/");
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleOpenMenu(state) {
    if (this._isMounted) {
      this.setState({ openMenu: state });
    }
  }

  renderButtons() {
    return (
      <>
        <Button onClick={() => this._clickAction("/about")}>{about}</Button>
        <Button
          onClick={() => {
            this._clickAction("/cursos");
          }}
        >
          {cursos}
        </Button>

        <Button onClick={() => this._clickAction("/contact")}>Contacto</Button>
        <Button onClick={() => this._clickAction("/terminos_y_condiciones")}>
          Términos y condiciones
        </Button>
      </>
    );
  }

  render() {
    const { match } = this.props;
    return (
      <WindowContext.Consumer>
        {(window) => {
          return (
            <div>
              {match.path !== "/" ? (
                <Slide direction="down" in={true} timeout={800}>
                  <AppBar
                    style={{
                      backgroundColor: "white",
                      boxShadow: "none",
                      color: "black",
                    }}
                    className="appbar"
                  >
                    <Toolbar className="barContainer">
                      <Button
                        className="home-button"
                        onClick={() => this._clickAction("/")}
                      >
                        Codingmexico
                      </Button>
                      {window.resize.width >= 850 ? (
                        <div className="button-container">
                          <Button onClick={() => this._clickAction("/about")}>
                            {about}
                          </Button>
                          <Button
                            onClick={() => {
                              this._clickAction("/cursos");
                            }}
                          >
                            {cursos}
                          </Button>

                          <Button onClick={() => this._clickAction("/contact")}>
                            Contacto
                          </Button>
                          <Button
                            onClick={() =>
                              this._clickAction("/terminos_y_condiciones")
                            }
                          >
                            Términos y condiciones
                          </Button>
                        </div>
                      ) : (
                        <>
                          <Button onClick={() => this.handleOpenMenu(true)}>
                            <Menu />
                          </Button>
                          <SwipeableDrawer
                            open={this.state.openMenu}
                            onClose={() => this.handleOpenMenu(false)}
                          >
                            <div
                              style={{
                                width: 300,
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "flex-start",
                                padding: 20,
                              }}
                            >
                              {this.renderButtons()}
                            </div>
                          </SwipeableDrawer>
                        </>
                      )}
                    </Toolbar>
                  </AppBar>
                </Slide>
              ) : (
                <Fade in={true} timeout={1000}>
                  <div className="menu">
                    <div className="header">
                      <h1>Codingmexico</h1>
                    </div>
                    <div className="button-container">
                      <Button onClick={() => this._clickAction("/about")}>
                        {about}
                      </Button>
                      <Button
                        onClick={() => {
                          this._clickAction("/cursos");
                        }}
                      >
                        {cursos}
                      </Button>

                      <Button onClick={() => this._clickAction("/contact")}>
                        Contacto
                      </Button>
                      <Button
                        onClick={() =>
                          this._clickAction("/terminos_y_condiciones")
                        }
                      >
                        Términos y condiciones
                      </Button>
                    </div>
                  </div>
                </Fade>
              )}
            </div>
          );
        }}
      </WindowContext.Consumer>
    );
  }
}

export default withRouter(AppHeader);
