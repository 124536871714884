export const cameraPositions = {
  vertical: {
    "side-face": {
      x: -4,
      z: 5,
      y: 1,
      rotationx: 0,
      rotationy: 0.5,
      rotationz: 0,
    },
    front: {
      x: 0.5,
      z: 4,
      y: 0.5,
      rotationx: 1,
      rotationy: 1,
      rotationz: 0,
    },
    back: {
      x: -5,
      z: -7,
      y: 2,
      rotationx: 1,
      rotationy: -0.5,
      rotationz: 0,
    },
    frontZoom: {
      x: -0.6,
      z: 0.5,
      y: 0.4,
      rotationx: -0.4,
      rotationy: 0.3,
      rotationz: -1,
    },
    screen: {
      x: 0,
      z: -0.2,
      y: 0.2,
      rotationx: 0,
      rotationy: 0,
      rotationz: 0,
    },
  },
  horizontal: {
    "side-face": {
      x: -4,
      z: 4,
      y: 1,
      rotationx: 1,
      rotationy: 0.5,
      rotationz: 0,
    },
    front: {
      x: 0.5,
      z: 3,
      y: 0.5,
      rotationx: 1,
      rotationy: 1,
      rotationz: 0,
    },
    back: {
      x: -5,
      z: -5,
      y: 2,
      rotationx: -2,
      rotationy: 1,
      rotationz: 0,
    },
    frontZoom: {
      x: -0.6,
      z: 0.5,
      y: 0.3,
      rotationx: -0.4,
      rotationy: 0.2,
      rotationz: -1,
    },
    screen: {
      x: 0,
      z: -0.2,
      y: 0.2,
      rotationx: 0,
      rotationy: 0,
      rotationz: 0,
    },
  },
  frontZoom2: {
    x: -0.5,
    z: -0.2,
    y: 0.3,
    rotationx: -0.4,
    rotationy: 0.2,
    rotationz: -1,
  },
};
