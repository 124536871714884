import React, { Component } from "react";
import { Phone, Mail, FileCopy, LinkedIn,Facebook, Instagram, Business } from "@material-ui/icons";
import { Avatar, Fade, IconButton, Tooltip } from "@material-ui/core";

class Contact extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      enter: false,
      copied: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    setTimeout(() => {
      if (this._isMounted) {
        this.setState({ enter: true });
      }
    }, 2100);
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  _copyClipboard(action) {
    let text;
    switch (action) {
      case "nefon":
        text = "449 579 3276";
        break;
      case "retos":
        text = "contacto@codingmexico.com";
        break;
      case "link":
        text = "https://linkedin.com/in/camilo-segovia-458a51195";
        break;
      default:
        break;
    }

    if (text) {
      navigator.clipboard.writeText(text);
    }
    if (this._isMounted) {
      this.setState({ copied: true });
    }
  }

  _mouseOut() {
    if (this._isMounted) {
      this.setState({ copied: false });
    }
  }

  _clickLink(e, type) {
    e.preventDefault();
    if (type === "mail") {
      window.open("mailto:contacto@codingmexico.com");
    }
    if (type === "number") {
      window.open(" https://wa.me/524491963812");
    }
  }

  render() {
    return (
      <>
        <Fade in={this.state.enter} timeout={500}>
          <section className="contactSection">
            <div className="contactContainer">
              <h1>¡Contáctanos!</h1>
              <span className="contactElement">
                <Avatar className="icon" style={{ backgroundColor: "#20BD4B" }}>
                  <Phone />
                </Avatar>

                <a
                  className="nefon"
                  href="/"
                  onClick={(e) => this._clickLink(e, "number")}
                >
                  975
                </a>
                <Tooltip
                  title={this.state.copied ? "Copiado" : "Copiar"}
                  onMouseLeave={() => this._mouseOut()}
                >
                  <IconButton
                    className="clipCopy"
                    onClick={() => this._copyClipboard("nefon")}
                  >
                    <FileCopy />
                  </IconButton>
                </Tooltip>
              </span>

              <span className="contactElement">
                <Avatar className="icon" style={{ backgroundColor: "#29B1C8" }}>
                  <Mail />
                </Avatar>

                <a
                  className=""
                  href="/"
                  onClick={(e) => this._clickLink(e, "mail")}
                >
                  contacto@codingmexico.com
                </a>
                <Tooltip
                  title={this.state.copied ? "Copiado" : "Copiar"}
                  onMouseLeave={() => this._mouseOut()}
                >
                  <IconButton
                    className="clipCopy"
                    onClick={() => this._copyClipboard("retos")}
                  >
                    <FileCopy />
                  </IconButton>
                </Tooltip>
              </span>
              <span className="contactElement">
                <Avatar className="icon" style={{ backgroundColor: "#3b5998" }}>
                  <Facebook />
                </Avatar>

                <a
                  className=""
                  href="https://www.facebook.com/profile.php?id=61552955457072"
                  target="_blank"
                >
                  codingmexico
                </a>
                
              </span>
              <span className="contactElement">
                <Avatar className="icon" style={{ backgroundColor: "#E1306C" }}>
                  <Instagram />
                </Avatar>

                <a
                  className=""
                  href="https://www.instagram.com/codingmexico/"
                  target="_blank"
                >
                  codingmexico
                </a>
                
              </span>
              
            </div>
          </section>
        </Fade>
      </>
    );
  }
}

export default Contact;
