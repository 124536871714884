import React, {Component} from "react";
import { Close} from "@material-ui/icons"
import { Fade, IconButton } from "@material-ui/core";

class FullScreenImage extends Component{
    render() {
        const {image, onClose} = this.props;
        return (
            <Fade in={true} timeout={800} unmountOnExit>
                 <div className="fullScreen">
                    <span className="closeButton">
                    <IconButton onClick={onClose}> 
                        <Close/>
                    </IconButton>
                    </span>
                    {image&&<div className="imageContainer">
                        <img src={image} alt="fullScreen"></img>
                    </div>}
                </div>
            </Fade>
        );
    }
}

export default FullScreenImage;

