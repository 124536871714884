import { Dialog } from "@material-ui/core";
import { Fab } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import AddIncome from "../components/dashboard/AddIncomeForm";
import firebase from "../Firebase";
import AddIcon from "@material-ui/icons/Add";

import { AuthContext } from "../utils/AuthContext";
import DashboardTable from "../components/DashboardTable";
import { WindowContext } from "../utils/WindowContext";

class DashboardContainer extends Component {
  static contextType = AuthContext;
  _isMounted = false;
  constructor(props, context) {
    super(props);
    if (context.currentUser === null) {
      this.redirect();
    }
    this.state = {
      showDialog: false,
      formType: "income",
    };
    this.db = firebase.firestore();
    // this.incomesAndExpenses = this.db.collection("incomesAndExpenses");
    this.categoriesCollection = this.db.collection("categories");
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  redirect() {
    this.props.history.push("/login");
  }

  handleDialogState() {
    if (this._isMounted) {
      this.setState({ showDialog: !this.state.showDialog });
    }
  }
  /**
   * Verifies if the category exists and create a new one if it's not
   * @param {String} category
   */
  async checkCategory(category, type) {
    let doc = await this.categoriesCollection
      .where("category", "==", category)
      .get();
    if (doc.docs.length === 0) {
      this.categoriesCollection.add({
        category,
        type,
      });
    }
  }

  handleSubmit = ({ date, type, category, amount, details }) => {
    return this.db
      .collection(type === "house_expense" ? "expense" : type)
      .add({
        date: moment(date).toDate(),
        category,
        type,
        amount,
        details: details || null,
      })
      .then((doc) => {
        if (doc) {
          this.checkCategory(category, type);
          this.setState({ showDialog: false });
          return "Ok";
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleFormChange(formType) {
    if (this._isMounted) {
      this.setState({ formType });
    }
  }

  render() {
    return (
      <div className="dashboardContainer">
        <WindowContext.Consumer>
          {(window) => {
            return <DashboardTable window={window.resize} />;
          }}
        </WindowContext.Consumer>
        <Dialog
          open={this.state.showDialog}
          onClose={() => this.handleDialogState()}
        >
          <div className="dialogHeader">
            {this.state.formType === "income"
              ? "Añadir Nuevo Ingreso"
              : "Añadir Nuevo Gasto"}
          </div>
          <div
            style={{
              display: "flex",
              padding: "20px",
              maxHeight: "1000px",
              width: "100%",
            }}
          >
            <AddIncome
              onSubmit={this.handleSubmit}
              onChangeForm={(e) => {
                this.handleFormChange(e);
              }}
            />
          </div>
        </Dialog>
        <Fab
          className="fab"
          color="primary"
          onClick={() => this.handleDialogState()}
        >
          <AddIcon />
        </Fab>
      </div>
    );
  }
}

export default withRouter(DashboardContainer);
