import { Fade,} from "@material-ui/core";
import React, {Component} from "react";
import ImageCarousel from "./ImageCarousel";
import firebase from "../Firebase";
import { withRouter } from "react-router";
import {ArrowForward, Language, Android } from "@material-ui/icons";
import {Button} from "@mui/material";

class SingleProject extends Component{
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state={
            display: false, 
            project: undefined
        }
        this.db = firebase.firestore()
    }
    
    async _getProject(){
        let docId = this.props.match.params.id;
      
        let docSnap = await this.db.doc(`projects/${docId}`).get();
        let project = {
            id: docSnap.id,
            name: docSnap.data()["name"],
            gallery: docSnap.data()["gallery"],
            description: docSnap.data()["description"],
            link:docSnap.data()["link"],
         }
       if (this._isMounted) {
            this.setState({project})
       }
       
    }
    
    componentDidMount(){
        this._isMounted = true;
        setTimeout(()=>{
            if (this._isMounted) {
                this.setState({display:true})
            }
        }, 1300)
        this._getProject();
    }
    componentWillUnmount(){
        this._isMounted = false;
    }

    _renderUrlButton(){
        switch (this.state.project.link.type) {
            case "web":
                return(
                    <Button startIcon={<Language/>} endIcon={<ArrowForward/>} >Ver sitio web</Button>
                );
            case "android":
               return(
                <Button startIcon={<Android/>} endIcon={<ArrowForward/>} >Ver enlace</Button>
               );
            default:
                break;
        }
    }

    _renderBody(){
        return(
            <div className="projectContainer">
            <section className="projectHeader">
            {this.state.project&& this.state.project.gallery &&<ImageCarousel images={this.state.project.gallery}/>}
            </section>   
            {this.state.project!==undefined&& <Fade in={this.state.project!==undefined}>
              <section className="section2">
              <h1 className="title">{this.state.project.name}</h1>
                    <div className="description">
                   
                        <p>{this.state.project.description}</p>
                      {this.state.project.link && <a href={this.state.project.link.url}  target="_blank" rel="noreferrer">
                          {this._renderUrlButton()}
                    </a>}
                    </div>
              </section>
              </Fade>}
           
        </div>
        );
    }
    render() {
        return (
             <>
             <Fade direction="up" in={this.state.display} timeout={500} unmountOnExit={true} mountOnEnter={true}>
             <div>
             <div className="background">
              
              </div>
              <div> {this._renderBody()}</div>
             </div>
               
             </Fade>
             </>
        );
    }
}

export default withRouter(SingleProject);
