import React, {Component} from "react";
import {ArrowBack, ArrowForward} from "@material-ui/icons";
import { Fade } from "@material-ui/core";
import FullScreenImage from "../components/FullScreenImage";

class ImageCarousel extends Component{
    _refs=[];
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state={
            currentSlide:0,
            currentTarget: undefined,
            showFullImg: undefined,
        }

        this.images =props.images
    }

    componentDidMount(){
        this._isMounted= true;
    }
    
    componentWillUnmount(){
        this._isMounted = false;
    }
   
    _onClickImage(index){
    
       if (this._isMounted) {
           this.setState({
               showFullImg: index,
           })
       }
    }

 

    _renderImages(img, index){
        
        return(
            <React.Fragment  key={index}>
               <Fade in={true} timeout={1000} >
               <li className="image-container" ref={ref=>(this._refs.push(ref))}>
               <img onClick={()=>this._onClickImage(index)} src={img} alt={index}></img>
                </li>
               </Fade>
            
               
            </React.Fragment>
        );
    }
    _onClick(slide){
        let index = slide<0? this.images.length-1: slide>this.images.length-1?0:slide;
      
        if (this._isMounted) {
            
            this.setState({
                currentSlide: index
            }, ()=>{
                this._refs[index].scrollIntoView({block:"end", behavior:"smooth"})
            })
        }
       
    }
    _onClose(){
        if (this._isMounted) {
            this.setState({
                showFullImg: undefined
            })
        }
    }
    render() {
        return (
          <>
              <ol className="carouselImg" >
           
           
           <div className="controlsContainer">
           <div className="arrowButtonBack" onClick={()=>this._onClick(this.state.currentSlide-1)}>
                               <div className="buttonContainer" >
                               <ArrowBack />
                               </div>
                        </div> 
                        <div className="arrowButtonNext" onClick={()=>this._onClick(this.state.currentSlide+1)}>
                               <div className="buttonContainer" >
                               <ArrowForward />
                               </div>
                        </div> 
           </div>
           {this.images.map((img, index)=>this._renderImages(img, index))}
        </ol>
         {this.state.showFullImg!==undefined&& <FullScreenImage onClose={()=>this._onClose()} image={this.images[this.state.showFullImg]}/>}
          </>
        );
    }
}

export default ImageCarousel;
