import { Fade, Slide } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { about } from "../utils/constants";

function About() {
  const [enter, setEnter] = useState(true);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);

  const [animation1, setAnimation1] = useState(false);
  const [animation2, setAnimation2] = useState(false);
  const [animation3, setAnimation3] = useState(false);
  const [animation4, setAnimation4] = useState(false);
  const [animation5, setAnimation5] = useState(false);

  const handleIntersection = (entries, observer, section) => {
    entries.forEach((entry) => {
      if (section == 1) setAnimation1(entry.isIntersecting);
      else if (section == 2) setAnimation2(entry.isIntersecting);
      else if (section == 3) setAnimation3(entry.isIntersecting);
      else if (section == 4) setAnimation4(entry.isIntersecting);
      else if (section == 5) setAnimation5(entry.isIntersecting);
    });
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer1 = new IntersectionObserver(
      (entries) => handleIntersection(entries, observer1, 1),
      options
    );
    const observer2 = new IntersectionObserver(
      (entries) => handleIntersection(entries, observer2, 2),
      options
    );
    const observer3 = new IntersectionObserver(
      (entries) => handleIntersection(entries, observer1, 3),
      options
    );
    const observer4 = new IntersectionObserver(
      (entries) => handleIntersection(entries, observer2, 4),
      options
    );
    const observer5 = new IntersectionObserver(
      (entries) => handleIntersection(entries, observer2, 5),
      options
    );
    if (section1.current) {
      observer1.observe(section1.current);
    }

    if (section2.current) {
      observer2.observe(section2.current);
    }
    if (section3.current) {
      observer3.observe(section3.current);
    }
    if (section4.current) {
      observer4.observe(section4.current);
    }
    if (section5.current) {
      observer5.observe(section5.current);
    }

    return () => {
      observer1.disconnect();
      observer2.disconnect();
      observer3.disconnect();
      observer4.disconnect();
      observer5.disconnect();
    };
  }, []);

  const _renderBody = () => {
    return (
      <div className="section-container">
        <section ref={section1}>
          <span className="titleContainer">
            <Slide in={animation1} direction="right" timeout={500}>
              <h1 style={{ fontSize: 40 }}>{about}</h1>
            </Slide>
          </span>

          <Fade timeout={1200} in={animation1}>
            <div style={{ padding: 50, fontSize: 30 }}>
              Somos un equipo dedicado de expertos en programación, desarrollo
              de aplicaciones y marketing digital, comprometidos con mejorar la
              calidad de la vida profesional de las personas. Nos esforzamos por
              proporcionar educación de calidad que no sólo impulse las
              carreras, sino que también enriquezca la vida de nuestros
              estudiantes, permitiéndoles alcanzar un equilibrio óptimo entre
              trabajo y estilo de vida.
            </div>
          </Fade>
        </section>
        <section ref={section2}>
          <span className="titleContainer">
            <Slide
              in={animation2}
              direction="right"
              timeout={500}
              appear={false}
            >
              <h1 style={{ fontSize: 40 }}>Misión</h1>
            </Slide>
          </span>
          <Fade timeout={1200} in={animation2}>
            <div style={{ padding: 50, fontSize: 30 }}>
              Nuestra misión es empoderar a las personas para que logren una
              mejor calidad de vida profesional. Lo hacemos proporcionando
              educación excepcional en programación, desarrollo web,
              aplicaciones y marketing digital. Nos esforzamos por ser
              catalizadores del crecimiento profesional, permitiendo a nuestros
              estudiantes no solo sobresalir en sus carreras, sino también
              disfrutar de una vida laboral significativa y satisfactoria.
            </div>
          </Fade>
        </section>
        <section ref={section3}>
          <span className="titleContainer">
            <Slide
              in={animation3}
              direction="right"
              timeout={500}
              appear={false}
            >
              <h1 style={{ fontSize: 40 }}>Visión</h1>
            </Slide>
          </span>
          <Fade timeout={1200} in={animation3}>
            <div style={{ padding: 50, fontSize: 30 }}>
              Nos visualizamos como un faro de luz en el mundo digital, guiando
              a individuos hacia carreras significativas y equilibradas.
              Aspiramos a ser líderes en la capacitación digital, no solo por el
              conocimiento que impartimos, sino por el impacto positivo que
              tenemos en la vida de nuestros estudiantes. Queremos ser
              reconocidos como arquitectos de carreras exitosas y vidas
              profesionales gratificantes.
            </div>
          </Fade>
        </section>
        <section ref={section4}>
          <span className="titleContainer">
            <Slide
              in={animation4}
              direction="right"
              timeout={500}
              appear={false}
            >
              <h1 style={{ fontSize: 40 }}>Valores</h1>
            </Slide>
          </span>
          <Fade timeout={1200} in={animation4}>
            <div style={{ padding: 50, fontSize: 30 }}>
              Excelencia: Nos esforzamos por la excelencia en la educación,
              brindando conocimientos actualizados y prácticos. Innovación:
              Abrazamos la innovación tecnológica y fomentamos la creatividad en
              nuestros estudiantes. Comunidad: Construimos una comunidad
              colaborativa y solidaria donde el aprendizaje es un esfuerzo
              conjunto. Ética: Operamos con integridad y ética, promoviendo un
              ambiente de confianza y respeto mutuo. Empoderamiento: Empoderamos
              a nuestros estudiantes para que se conviertan en líderes en la era
              digital, capacitándolos con habilidades valiosas y
              transformadoras.
            </div>
          </Fade>
        </section>
        <section ref={section5}>
          <span className="titleContainer">
            <Slide
              in={animation5}
              direction="right"
              timeout={500}
              appear={false}
            >
              <h1 style={{ fontSize: 40 }}>Visión</h1>
            </Slide>
          </span>
          <Fade timeout={1200} in={animation5}>
            <div style={{ padding: 50, fontSize: 30 }}>
              Nos visualizamos como un faro de luz en el mundo digital, guiando
              a individuos hacia carreras significativas y equilibradas.
              Aspiramos a ser líderes en la capacitación digital, no solo por el
              conocimiento que impartimos, sino por el impacto positivo que
              tenemos en la vida de nuestros estudiantes. Queremos ser
              reconocidos como arquitectos de carreras exitosas y vidas
              profesionales gratificantes.
            </div>
          </Fade>
        </section>
      </div>
    );
  };

  return (
    <>
      <div className="aboutSection">{_renderBody()}</div>
    </>
  );
}

export default About;
