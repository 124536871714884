import { Button, TextField } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { change, Field, reduxForm } from "redux-form";

const validate = (values) => {
  const error = {};

  if (!values.start) {
    error.start = "Completa este campo";
  }
  if (!values.end) {
    error.end = "Completa este campo";
  }
  if (moment(values.end).diff(values.start) < 0) {
    error.end = "El rango de fechas no es válido";
  }
  return error;
};

class SelectDateForm extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.dispatch(
      change("DateForm", "start", moment(this.props.start).format("yyyy-MM-D"))
    );
    this.props.dispatch(
      change("DateForm", "end", moment(this.props.end).format("yyyy-MM-DD"))
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderCalendar = ({ input, meta, label, defaultValue }) => (
    <div>
      <TextField
        onChange={input.onChange}
        id="date"
        label={label}
        type="date"
        style={{ width: "100%" }}
        className="dateInput"
        defaultValue={defaultValue}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {meta.touched && meta.error && (
        <span style={{ color: "red", fontWeight: "bold" }}>{meta.error}</span>
      )}
    </div>
  );

  render() {
    const { handleSubmit, start, end } = this.props;

    return (
      <form className="formGroup" onSubmit={handleSubmit}>
        <Row>
          <Col
            xl="6"
            style={{
              marginBottom: 20,
            }}
          >
            <Field
              name="start"
              component={this.renderCalendar}
              label="Fecha de inicio"
              defaultValue={moment(start).format("yyyy-MM-DD")}
            />
          </Col>
          <Col
            xl="6"
            style={{
              marginBottom: 20,
            }}
          >
            <Field
              name="end"
              component={this.renderCalendar}
              label="Fecha de fin"
              defaultValue={moment(end).format("yyyy-MM-DD")}
            />
          </Col>
        </Row>
        <Button className="btn" type="submit">
          Aceptar
        </Button>
      </form>
    );
  }
}

const DateForm = reduxForm({
  form: "DateForm",
  validate,
})(SelectDateForm);

export default DateForm;
