import React, {createContext, useState, useEffect} from "react";
import * as THREE from "three";


export const LoaderContext = createContext();
export const manager = new THREE.LoadingManager();
function LoaderProvider({children}) {
    const [progress, setprogress] = useState(0);
    useEffect(() => {
      manager.onStart = (url, itemsLoaded, itemsTotal)=>{
        setprogress(itemsLoaded/itemsTotal*100)
      }
        manager.onProgress = (url, itemsLoaded, itemsTotal)=>{
            setprogress(itemsLoaded/itemsTotal*100)
          
        }
      manager.onLoad=(url, itemsLoaded, itemsTotal)=>{
        setprogress("loaded")
      
    }
   
 
        return () => {
            
        };
    }, [progress]);
    const values = {
        progress
    }
    return(
        <LoaderContext.Provider value= {values}>
               {children}
        </LoaderContext.Provider>
    )
   
}

export default LoaderProvider;