import React, { createContext, useState, useEffect } from "react";

export const WindowContext = createContext();

export default function WindowProvider({ children }) {
  const [resize, setresize] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  function _eventListener() {
    setresize({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }

  useEffect(() => {
    window.addEventListener("resize", _eventListener, false);
    return () => {
      window.removeEventListener("resize", _eventListener);
    };
  }, [resize]);

  const values = {
    resize,
  };

  return (
    <WindowContext.Provider value={values}>{children}</WindowContext.Provider>
  );
}
