import { Fade } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useParams,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import CursosWeb from "../components/Cursos/CursosWeb";
import CursosApp from "../components/Cursos/CursosApp";
import ProxCursos from "../components/Cursos/ProxCursos";

function Cursos(props) {
  const [display, setDisplay] = useState(false);
  const [init, setInit] = useState(false);
  const params = useParams();
  useEffect(() => {
    if (!props.location.from) {
      setInit(true);
    }
    if (
      !props.location.from?.startsWith("/cursos/") &&
      !props.location.from?.startsWith("/preguntas")
    ) {
      setTimeout(() => {
        setDisplay(true);
      }, 1300);
    } else {
      setDisplay(true);
    }
  }, []);

  const handleCurso = () => {
    switch (params["id"]) {
      case "web":
        return <CursosWeb />;
      case "app":
        return <CursosApp />;
      case "proximos":
        return <ProxCursos />;
      default:
        return;
    }
  };

  return (
    <Fade
      direction="up"
      in={display || init}
      timeout={init ? 0 : display ? 500 : 0}
      unmountOnExit={true}
      mountOnEnter={true}
    >
      <div className="background" style={{ marginTop: 60, width: "100vw" }}>
        {handleCurso()}
      </div>
    </Fade>
  );
}

export default withRouter(Cursos);
