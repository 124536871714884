import React, {Component} from "react";
import { withRouter } from "react-router";
import { LoaderContext } from "../utils/LoaderContext";
import AppHeader from "./AppHeader";
import LoaderBar from "./LoaderBar";
import Seekerbot from "./Seekerbot";

class AppFrame extends Component{
    static contextType = LoaderContext;
    constructor(props) {
        super(props);
        this.state={
            loaded : false
        }
    }
    

   

    render() {
        const {body} = this.props;
  
        return (
             <div>
                {this.context.progress ==="loaded"&&<AppHeader/>}
                 
                 <section className="page-wrapper">
                 <Seekerbot/>
                   
                     {this.context.progress ==="loaded"?
                     body:
                     <LoaderBar/>
                     }
                    
                 </section>
             </div>
        );
    }
}

export default withRouter(AppFrame);