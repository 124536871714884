import "./App.css";
import Home from "./containers/Home";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoaderProvider from "./utils/LoaderContext";
import AuthProvider from "./utils/AuthContext";
import WindowProvider from "./utils/WindowContext";

function App() {
  return (
    <Router>
      <LoaderProvider>
        <AuthProvider>
          <WindowProvider>
            {/* <Brainbolt /> */}
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/cursos" component={Home} />
              <Route exact path="/contact" component={Home} />
              <Route exact path="/about" component={Home} />
              <Route exact path="/about" component={Home} />
              <Route exact path="/login" component={Home} />
              <Route exact path="/dashboard" component={Home} />
              <Route exact path="/cursos/:id" component={Home} />
              <Route exact path="/terminos_y_condiciones" component={Home} />
              <Route exact path="/preguntas" component={Home} />
            </Switch>
          </WindowProvider>
        </AuthProvider>
      </LoaderProvider>
    </Router>
  );
}

export default App;
