import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { Component } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Login from "../components/login/Login";
import firebase from "../Firebase";
import { AuthContext } from "../utils/AuthContext";

class LoginContainer extends Component {
  _isMounted = false;
  static contextType = AuthContext;
  constructor(props, context) {
    super(props);
    this.state = {
      showDialog: false,
      error: {
        title: undefined,
        message: undefined,
      },
    };
    if (context.currentUser !== null) {
      this.redirect();
    }
  }

  redirect() {
    this.props.history.push("/dashboard");
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleCloseDialog() {
    if (this._isMounted) {
      this.setState({ showDialog: false });
    }
  }

  /**
   *
   * @param {firebase.FirebaseError} error
   */
  handleErrorMessage(error) {
    switch (error.code) {
      case "auth/invalid-email":
        this.setState({
          showDialog: true,
          error: {
            title: "ERROR",
            message: "El correo no es válido",
          },
        });
        break;
      case "auth/user-not-found":
        this.setState({
          showDialog: true,
          error: {
            title: "ERROR",
            message: "El correo no existe",
          },
        });
        break;
      case "auth/wrong-password":
        this.setState({
          showDialog: true,
          error: {
            title: "ERROR",
            message: "Usuario o contraseña incorrectos",
          },
        });
        break;
      default:
        this.setState({
          showDialog: true,
          error: {
            title: "ERROR",
            message: "Ha ocurrido un error desconocido",
          },
        });
        break;
    }
  }

  handleSubmit = async (values) => {
    const email = values.email;
    const password = values.password;
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        this.props.history.push("/dashboard");
      })
      .catch((e) => {
        this.handleErrorMessage(e);
      });
  };

  render() {
    return (
      <div className="loginSection">
        <div className="loginContainer">
          <h1>Iniciar Sesión</h1>
          <Login onSubmit={this.handleSubmit} />
        </div>
        <Dialog
          open={this.state.showDialog}
          onClose={() => this.handleCloseDialog()}
        >
          <DialogTitle>{this.state.error.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.state.error.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleCloseDialog()}>Aceptar</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(LoginContainer);
