import {
  TextField,
  Button,
  Select,
  MenuItem,
  Autocomplete,
} from "@material-ui/core";
import moment from "moment";
import React, { Component } from "react";
import { reduxForm, Field, change } from "redux-form";
import firebase from "../../Firebase";

const validate = (values) => {
  const error = {};

  if (!values.category) {
    error.category = "Especifica a que categoría pertenece el ingreso";
  }
  if (!values.amount) {
    error.amount = "Ingresa la cantidad";
  }
  if (!values.date) {
    error.date = "Especfica la fecha del ingreso";
  }

  return error;
};

class AddIncome extends Component {
  _isMounted = false;
  _listeners = [];
  constructor(props) {
    super(props);
    this.currentDate = moment(Date.now()).format("yyyy-MM-DD");
    this.typesList = [
      { label: "Ingreso", value: "income" },
      { label: "Gasto", value: "expense" },
      { label: "Gasto Casa", value: "house_expense" },
    ];
    this.state = {
      type: this.typesList[0].value,
      categories: [],
    };
    this.db = firebase.firestore();
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.dispatch(change("AddIncomeForm", "date", this.currentDate));
    this.props.dispatch(change("AddIncomeForm", "type", this.state.type));
    this.props.onChangeForm(this.state.type);
    this.getCategories(this.state.type);
  }

  async getCategories(type) {
    let categories = [];
    let listener = this.db
      .collection("categories")
      .where("type", "==", type)
      .onSnapshot((snap) => {
        if (snap.docs) {
          snap.docs.forEach((doc) => {
            categories.push(doc.data()["category"]);
          });
          if (this._isMounted) {
            this.setState({ categories });
          }
        }
      });
    this._listeners.push(listener);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._listeners.forEach((listener) => listener());
  }

  renderField = ({ input, meta, type, placeholder, style }) => (
    <div className="inputContainer">
      <input {...input} type={type} placeholder={placeholder} style={style} />
      {meta.touched && meta.error && <span>{meta.error}</span>}
    </div>
  );

  renderTextArea = ({ input, type, placeholder, style }) => (
    <div className="inputContainer">
      <textarea
        style={style}
        {...input}
        type={type}
        placeholder={placeholder}
        rows={10}
      />
    </div>
  );

  renderCalendar = ({ input, type, placeholder, label }) => (
    <TextField
      onChange={input.onChange}
      id="date"
      label={label}
      type="date"
      style={{ width: "100%" }}
      className="dateInput"
      defaultValue={this.currentDate}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );

  renderSelect = ({ input, type, placeholder, label }) => (
    <Select
      style={{ width: "100%", marginBottom: 20 }}
      value={input.value}
      onChange={(e) => {
        this.props.onChangeForm(e.target.value);
        this._listeners.forEach((listener) => listener());
        this.getCategories(e.target.value);
        input.onChange(e);
      }}
      // value={this.state.type}
      // onChange={(event) => this.onTypeChange(event.target.value)}
    >
      {this.typesList.map((type) => {
        return <MenuItem value={type.value}>{type.label}</MenuItem>;
      })}
    </Select>
  );

  renderAutocomplete = ({ input, style, label, options, meta }) => {
    return (
      <div style={{ width: "100%", ...style }}>
        <Autocomplete
          freeSolo
          onBlur={input.onBlur}
          onFocus={input.onFocus}
          onDragStart={input.onDragStart}
          onDrop={input.onDrop}
          value={
            typeof input.value === "number"
              ? this.state.categories[input.value]
              : input.value
          }
          loading={options.length === 0 ? true : false}
          loadingText="Cargando..."
          style={{ width: "100%" }}
          options={options}
          // getOptionLabel={(option) => {
          //   return option;
          // }}
          renderInput={(params) => (
            <TextField
              label={label}
              margin="normal"
              variant="outlined"
              {...params}
            />
          )}
        />
        {meta.touched && meta.error && (
          <span style={{ color: "red", marginBottom: 20, fontWeight: "bold" }}>
            {meta.error}
          </span>
        )}
      </div>
    );
  };

  onTypeChange(type) {
    if (this._isMounted) {
      this.setState({ type });
    }
  }

  render() {
    const { handleSubmit, pristine, submitting } = this.props;

    return (
      <form
        className="formGroup"
        style={{ width: "30rem" }}
        onSubmit={handleSubmit}
      >
        <Field name="type" component={this.renderSelect} />
        <Field
          name="amount"
          type="number"
          placeholder="$ Cantidad"
          component={this.renderField}
          style={{ marginBottom: "1rem" }}
        />
        <Field
          name="category"
          component={this.renderAutocomplete}
          label="Categoría"
          options={this.state.categories}
          style={{ paddingBottom: "1rem" }}
        />
        <Field
          name="date"
          component={this.renderCalendar}
          label="Fecha de ingreso"
        />
        <Field
          name="details"
          placeholder="Agrega una nota"
          type="text"
          component={this.renderTextArea}
          style={{
            marginBottom: 30,
          }}
        />

        <Button className="btn" type="submit" disabled={pristine || submitting}>
          Guardar
        </Button>
      </form>
    );
  }
}

const AddIncomeForm = reduxForm({
  form: "AddIncomeForm",
  validate,
})(AddIncome);

export default AddIncomeForm;
