import React from "react";
import img1 from "../../assets/img/fun-team-work.jpg";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Fade } from "@mui/material";

function CursosWeb(props) {
  const _onClick = (e) => {
    console.log(props);
    e.preventDefault();
    props.history.push({
      pathname: `/cursos/app`,
      from: "/cursos/web",
    });
  };

  return (
    <div className="cursoCnt">
      <Fade in={true} timeout={800}>
        <div className="cursoInfo">
          <div>
            <h1 style={{ fontWeight: "bold" }}>
              Curso de Desarrollo de Páginas Web:
            </h1>
            <p style={{ marginTop: 30 }}>
              Nuestro curso de desarrollo de páginas web está diseñado para
              enseñar los fundamentos y las últimas tendencias en diseño web.
              Los participantes aprenderán a crear sitios web interactivos y
              receptivos utilizando tecnologías modernas como HTML5, CSS3 y
              JavaScript. Cinco características clave del curso incluyen:
            </p>
            {/* <h3 style={{ fontWeight: "bold" }}>¿Qué aprenderás?</h3> */}
            <ul style={{ marginTop: 20 }}>
              <li>
                Diseño Responsivo: Aprenderás a crear sitios web que se adapten
                a diferentes dispositivos y tamaños de pantalla.
              </li>
              <li>
                Optimización de Velocidad: Cómo optimizar el rendimiento del
                sitio para una carga rápida y una experiencia del usuario
                mejorada.
              </li>
              <li>
                Interactividad: Incorporación de elementos interactivos como
                formularios y animaciones para aumentar la participación del
                usuario.
              </li>
              <li>
                SEO Básico: Fundamentos de optimización para motores de búsqueda
                para aumentar la visibilidad del sitio en línea.
              </li>
              <li>
                Prácticas de Seguridad: Métodos para proteger el sitio web
                contra amenazas cibernéticas y ataques maliciosos.
              </li>
            </ul>
          </div>
          <img src={img1} className="cursoImg" />
        </div>
      </Fade>
      <div className="footer">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            height: "100%",
            padding: "0px 20px",
          }}
        >
          <a className="redirectBtn" href={`#`} onClick={_onClick}>
            <span>Curso de Elaboración de Aplicaciones (Apps)</span>
            <KeyboardArrowRight />
          </a>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CursosWeb);
