import {
  AppBar,
  Avatar,
  CircularProgress,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import moment from "moment";
import "moment/locale/es";
import React, { Component } from "react";
import SelectDate from "./dashboard/SelectDate";
import firebase from "../Firebase";
import { CallMade, CallReceived, Close, HomeRounded } from "@material-ui/icons";
import { Button, Dialog } from "@material-ui/core";
import Details from "./dashboard/Details";
import PdfContainer from "../containers/PdfContainer";
import { CloudUpload } from "@material-ui/icons";

export default class DashboardTable extends Component {
  _isMounted = false;
  _listeners = [];
  constructor(props) {
    super(props);
    moment.locale("es");
    this.db = firebase.firestore();
    this.state = {
      incomes: [],
      expenses: [],
      date: {
        startDate: moment(new Date()).startOf("day").toDate(),
        endDate: moment(new Date()).startOf("day").toDate(),
      },
      isLoading: true,
      renderDialog: undefined,
      fullScreen: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getIncomesAndExpenses();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._listeners.forEach((listener) => listener());
  }
  /**
   *
   * @param {firebase.firestore.QueryDocumentSnapshot} doc
   * @returns
   */
  setData(doc) {
    return {
      name: doc.data()["category"],
      date: new Date(doc.data()["date"].seconds * 1000),
      amount: doc.data()["amount"],
      type: doc.data()["type"],
      details: doc.data()["details"],
    };
  }

  async getIncomesAndExpenses() {
    let expenseListener = this.db
      .collection("expense")
      .where("date", ">=", this.state.date.startDate)
      .where(
        "date",
        "<",
        moment(this.state.date.endDate).add(1, "days").toDate()
      )
      .onSnapshot((snapshot) => {
        if (snapshot.docs.length > 0) {
          let expenses = snapshot.docs.map((doc) => this.setData(doc));
          if (this._isMounted) {
            this.setState({ expenses, isLoading: false });
          }
        } else {
          this.setState({ isLoading: false });
        }
      });
    let incomeListener = this.db
      .collection("income")
      .where("date", ">=", this.state.date.startDate)
      .where(
        "date",
        "<",
        moment(this.state.date.endDate).add(1, "days").toDate()
      )
      .onSnapshot((snapshot) => {
        let incomes = snapshot.docs.map((doc) => this.setData(doc));
        if (this._isMounted) {
          this.setState({ incomes, isLoading: false });
        }
      });
    this._listeners.push(expenseListener, incomeListener);
  }

  renderTile = ({ name, date, amount, type, details }, index) => {
    return (
      <Slide key={`${name}${index}`} direction="right" in={true} timeout={1000}>
        <div style={{ width: "100%" }}>
          {" "}
          <div
            className="tile"
            onClick={(e) => {
              if (this._isMounted) {
                this.setState({
                  renderDialog: this.renderElementDetails({
                    name,
                    date,
                    amount,
                    type,
                    details,
                  }),
                });
              }
            }}
          >
            {type === "house_expense" && (
              <Avatar style={{ width: 30, height: 30, marginRight: 10 }}>
                <HomeRounded />
              </Avatar>
            )}
            <div className="tileTitle">
              <h5>{name}</h5>
              <p>
                {moment(date).format(
                  this.props.window.width >= 660
                    ? "D \\d\\e MMMM yyyy"
                    : "D/MM/yyyy"
                )}
              </p>
            </div>
            <div
              style={{
                textAlign: "end",
                backgroundColor:
                  type === "income" ? "rgb(50 149 11)" : "#D51000",
                color: "white",
                fontWeight: "bold",
                width: "fit-content",
                padding: 10,
                borderRadius: 20,
              }}
            >
              {` ${type === "income" ? "+" : "-"} $${amount}`}
            </div>
          </div>
        </div>
      </Slide>
    );
  };

  handleChangeDate({ startDate, endDate }) {
    if (this._isMounted) {
      this.setState(
        {
          date: {
            startDate,
            endDate,
          },
          incomes: [],
          expenses: [],
          isLoading: true,
        },
        () => {
          this._listeners.forEach((listener) => listener());
          this.getIncomesAndExpenses();
        }
      );
    }
  }

  getTotalAmount(type) {
    let total = 0;
    switch (type) {
      case "income":
        this.state.incomes.forEach((income) => {
          total = total + parseFloat(income.amount);
        });
        return Number(total).toFixed(2);

      case "expense":
        this.state.expenses.forEach((expense) => {
          total = total + parseFloat(expense.amount);
        });
        return Number(total).toFixed(2);

      case "profit":
        this.state.incomes.forEach((expense) => {
          total = total + parseFloat(expense.amount);
        });
        this.state.expenses.forEach((expense) => {
          total = total - parseFloat(expense.amount);
        });

        return Number(total).toFixed(2);

      default:
        break;
    }
  }

  handleDialogState() {
    if (this._isMounted) {
      this.setState({
        renderDialog: undefined,
        fullScreen: false,
      });
    }
  }

  renderElementDetails(details) {
    return (
      <>
        <div className="dialogHeader">
          <div style={{ display: "flex" }}>
            <Avatar style={{ backgroundColor: "white", marginRight: 10 }}>
              {details.type === "income" ? (
                <CallMade style={{ color: "rgb(50 149 11)" }} />
              ) : (
                <CallReceived style={{ color: "#D51000" }} />
              )}
            </Avatar>
            {details.name}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            padding: "20px",
            maxHeight: "1000px",
            width: "100%",
          }}
        >
          <Details details={details} />
        </div>
      </>
    );
  }

  onExport() {
    this.setState({
      renderDialog: (
        <>
          <AppBar>
            <Toolbar>
              <IconButton onClick={() => this.handleDialogState()}>
                <Close style={{}} />
              </IconButton>
              <Typography>Vista Previa</Typography>
            </Toolbar>
          </AppBar>
          <PdfContainer
            incomes={this.state.incomes}
            expenses={this.state.expenses}
            date={this.state.date}
          />
        </>
      ),
      fullScreen: true,
    });
  }

  render() {
    return (
      <div className="dash">
        <Dialog
          fullScreen={this.state.fullScreen}
          open={this.state.renderDialog ? true : false}
          onClose={() => this.handleDialogState()}
        >
          {this.state.renderDialog}
        </Dialog>
        <div style={{ width: "60%" }}>
          <SelectDate onChangeDate={(dates) => this.handleChangeDate(dates)} />
        </div>
        <Button onClick={() => this.onExport()}>
          <CloudUpload style={{ marginRight: "10px" }} /> Exportar
        </Button>

        <div className="dashboardTable">
          <div className="headerTable">
            <div style={{ display: "flex", alignItems: "center" }}>
              <CallMade style={{ fontSize: 40, color: "rgb(50 149 11)" }} />{" "}
              <h1>Ingresos</h1>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CallReceived style={{ fontSize: 40, color: "#D51000" }} />{" "}
              <h1>Gastos</h1>
            </div>
          </div>
          {this.state.isLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </div>
          ) : this.state.incomes.length === 0 &&
            this.state.expenses.length === 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              Sin datos
            </div>
          ) : (
            <div className="tableBody">
              <div>
                {this.state.incomes.map((income, index) =>
                  this.renderTile(income, index)
                )}
              </div>
              <div>
                {this.state.expenses.map((expense, index) =>
                  this.renderTile(expense, index)
                )}
              </div>
            </div>
          )}
        </div>
        <div className="table-footer">
          <div className="totalLabel">
            <p className="t-type">Ingresos</p>
            <p className="amount">{`$ ${this.getTotalAmount("income")}`}</p>
          </div>
          <div className="totalLabel">
            <p className="t-type">Gastos</p>
            <p className="amount">{`$ ${this.getTotalAmount("expense")}`}</p>
          </div>
          <div className="profitLabel">
            <p className="t-type">Utilidad</p>
            <p className="amount"> {`$ ${this.getTotalAmount("profit")}`}</p>
          </div>
        </div>
      </div>
    );
  }
}
