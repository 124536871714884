import { CircularProgress } from "@material-ui/core";
import React, {Component} from "react";
import { ProgressBar } from "react-bootstrap";
import { LoaderContext } from "../utils/LoaderContext";

class LoaderBar extends Component{
    static contextType = LoaderContext;
    render() {
        return (
            <div className="loaderBar" >
                <CircularProgress
                variant="determinate"
                size={200}
                 value={this.context.progress==="loaded"?100:this.context.progress}
                 />
                 <span className="label">
                 {this.context.progress==="loaded"?  "100%":`${this.context.progress.toFixed(2)}%`}
                 </span>
             Cargando...
             
          </div>
        );
    }
}

export default LoaderBar;