import React, { Component } from "react";
import AppFrame from "../components/AppFrame";
import About from "./About";
import Contact from "./Contact";
import LoginContainer from "./LoginContainer";
import DashboardContainer from "./DashBoardContainer";
import MyWork from "./MyWork";
import SingleProject from "./SingleProject";
import TerminosCondiciones from "./TerminosCondiciones";
import Cursos from "./Cursos";
import Q_A from "../components/Q_A";

class Home extends Component {
  _renderComponent() {
    switch (this.props.match.path) {
      case "/cursos":
        return (
          <>
            <MyWork />
          </>
        );
      case "/about":
        return (
          <>
            <About />
          </>
        );
      case "/cursos/:id":
        return (
          <>
            <Cursos />
          </>
        );
      case "/contact":
        return (
          <>
            <Contact />
          </>
        );
      case "/login":
        return (
          <>
            <LoginContainer />
          </>
        );
      case "/dashboard":
        return (
          <>
            <DashboardContainer />
          </>
        );
      case "/terminos_y_condiciones":
        return (
          <>
            <TerminosCondiciones />
          </>
        );
      case "/preguntas":
        return (
          <>
            <Q_A />
          </>
        );
      default:
        return (
          <>
            <p></p>
          </>
        );
    }
  }
  _renderBody() {
    return (
      <React.Fragment>
        <div className="body">{this._renderComponent()}</div>
      </React.Fragment>
    );
  }

  _clickHandler() {}
  render() {
    return (
      <>
        <AppFrame body={this._renderBody()} clickEvent={() => {}} />
      </>
    );
  }
}

export default Home;
