import firebase from "../Firebase";
import React, { useState, useEffect, createContext } from "react";

export const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      setCurrentUser(user);
    });
    return () => {
      unsubscribe();
    };
  }, [currentUser]);

  const values = {
    currentUser,
  };
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}
