import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { Button } from "@material-ui/core";
const validate = (values) => {
  const error = {};
  if (!values.email) {
    error.email = "Escribe el correo por favor";
  }
  if (!values.password) {
    error.password = "Escribe la contraseña por favor";
  }
  return error;
};

class Login extends Component {
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  renderInput = ({ input, type, meta, placeholder, label, style }) => (
    <div className="inputContainer">
      <label>{label}</label>
      <input
        style={{ userSelect: "text", ...style }}
        {...input}
        type={type}
        placeholder={placeholder}
      />
      {meta.touched && meta.error && <span>{meta.error}</span>}
    </div>
  );

  render() {
    const { handleSubmit, pristine, submitting } = this.props;

    return (
      <form className="formGroup" onSubmit={handleSubmit}>
        <Field
          name={"email"}
          placeholder="Correo electrónico"
          type="text"
          label="Correo electrónico"
          component={this.renderInput}
          style={{ marginBottom: "2rem" }}
        />
        <Field
          name="password"
          placeholder="Contraseña"
          type="password"
          label="Contraseña"
          component={this.renderInput}
          style={{ marginBottom: "2rem" }}
        />
        <Button className="btn" type="submit" disabled={pristine || submitting}>
          Iniciar Sesión
        </Button>
      </form>
    );
  }
}

const LoginForm = reduxForm({
  form: "LoginForm",
  validate,
})(Login);
export default LoginForm;
